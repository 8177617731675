import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Estacionamientos, Inc. <br />
          Under construction
        </p>
      </header>
    </div>
  );
}

export default App;
